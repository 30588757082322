const NFT_START = 1615140897000;
const MAIN_START = 1614664800000;
const STAKING_POOL = [
  {
    pool: "USDLLGD",
    stakeToken: "USDL",
    getToken: "LGD",
    stakeTokenName: "USDL",
    getTokenName: "Legendex token",
  },
  {
    pool: "ETHLGD",
    stakeToken: "ETH",
    getToken: "LGD",
    stakeTokenName: "Ethereum",
    getTokenName: "Legendex token",
  },
  {
    pool: "HTLGD",
    stakeToken: "HT",
    getToken: "LGD",
    stakeTokenName: "Huobi Token",
    getTokenName: "Legendex token",
  },
];

const TOTAL_APPROVE = 1000000000;
const CONFIRM_NUMBER = 1;
const SOCIAL_LINK = {
  telegram: "https://t.me/NFTLegends_Group",
  telegramAnn: "https://t.me/NFTLegends_ANN",
  twitter: "https://twitter.com/nft_legend",
  medium: "https://medium.com/@nft_legends",
  github: "",
};

// MAINNET
const NETWORK = 1;
const TOKEN_INFO = {
  USDT: { address: "0xdac17f958d2ee523a2206206994597c13d831ec7", decimal: 6 },
  USDC: { address: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48", decimal: 6 },
  DAI: { address: "0x6b175474e89094c44da98b954eedeac495271d0f", decimal: 18 },
  BUSD: { address: "0x4fabb145d64652a948d72533023f6e7a623c7c53", decimal: 18 },

  USDL: { address: "0x9202e377184daa261a36875ed9158143d18599F8", decimal: 18 },
  LGD: { address: "0x9a623d23b47fF6776BCc2e7146f0588b09120975", decimal: 18 },
  LGN: { address: "0x85fc902bBE53637351820d7Cb88ba69475fBc012", decimal: 18 },
  LGDLP: { address: "0x63FFD957e29d0f25EAD4952338003CAe9551475D", decimal: 18 }, //! CHANGE IT LATER
};
const PAIR_ID = {
  //PAIR ID on UNISWAP => lower case
  LGD: "0x63ffd957e29d0f25ead4952338003cae9551475d", //! CHANGE IT LATER
  ETH: "0x0d4a11d5eeaac28ec3f61d100daf4d40471f1852",
  LGN: "0x0de0fa91b6dbab8c8503aaa2d1dfa91a192cb149", //! CHANGE IT LATER
};
const EXPLORER = "https://etherscan.io/";
const INFURA = "https://mainet.infura.io/v3/b290a50e9362493e8882b002b9e44eb3";

// ROPSTEN
// const NETWORK = 3
// const TOKEN_INFO = {
//         // 'ETH': {address: '', decimal: 18},
//         'USDT':{address: '0xbbd27078d9725b60251722c7bd24c6c608d521ec', decimal: 6},
//         'BUSD':{address: '0x5882d1a2aa34624511b212a168e73cba71657a5d', decimal: 18},
//         'USDC':{address: '0xAA7c0132F533f0c55644870E1d5B7779C4cE8C83', decimal: 6},
//         'DAI':{address: '0x1efb2233542ce1eb8a8acdd85924b0123e1ea872', decimal: 18},

//         'USDL':{address: '0x78861195a086edf7D0728c70CAB8C35E1b72C5E2', decimal: 18},
//         'LGD':{address: '0xce688547Ac11554e1A68fB9e9e43FD92e0b9F127', decimal: 18},
//         'LGN':{address: '0xF092fF8B31E01b6581315Fb72F20c4348b47BfBc', decimal: 18},
//         'LGDLP':{address: '0x1B35D218F195378a6ecA74E4B6047eD0785F30e1', decimal: 18},
//         }
// const PAIR_ID = {//PAIR ID on UNISWAP => lower case
//         'LGD': '0x0d4a11d5eeaac28ec3f61d100daf4d40471f1852',
//         'ETH': '0x0d4a11d5eeaac28ec3f61d100daf4d40471f1852',
//         'LGN': '0x0de0fa91b6dbab8c8503aaa2d1dfa91a192cb149',
// }
// const EXPLORER = "https://ropsten.etherscan.io/"
// const INFURA = "https://ropsten.infura.io/v3/b290a50e9362493e8882b002b9e44eb3"

// TESTNET HT
// const NETWORK = 256
// const TOKEN_INFO = {
//         'ETH': {address: '0xccebfdc5db452ca48da6e557090d4be8d4436291', decimal: 18},
//         'USDT':{address: '0xfd3f0f7be87490a7f43a9d015163dca656f816e1', decimal: 18},
//         'HUSD':{address: '0x782c338d6ccf4fb4fa198214a8a0492a8c652b9d', decimal: 8},
//         'USDL':{address: '0x022D63fb360Eaa321aF1b700f338284ea5BA6abD', decimal: 18},
//         'LGD':{address: '0x6Bb103e45Fc59148Ec2290d921b12E666705a061', decimal: 18},
//         'LGN':{address: '0x44fba9c8838C88378F04b0ED37c7724EC0B07EF2', decimal: 18},
//         // 'LGDLP':{address: '0x02f9A046565C2e2c8173f73927C16A75799120be', decimal: 18},
//         'LGDLP':{address: '0x02f9A046565C2e2c8173f73927C16A75799120be', decimal: 18},
//         'NFTPOOL':{address: '0xdf5db350a96960dce4086995967d2a80535fd71c', decimal: 18},

//         }
// const PAIR_ID = {//PAIR ID on UNISWAP => lower case
//         'LGD': '0x78c90d3f8a64474982417cdb490e840c01e516d4',
//         'ETH': '0x78c90d3f8a64474982417cdb490e840c01e516d4',
//         'LGN': '0x78c90d3f8a64474982417cdb490e840c01e516d4',
// }

// MAINET HT
// const NETWORK = 128
// const TOKEN_INFO = {
//         'ETH': {address: '0x64FF637fB478863B7468bc97D30a5bF3A428a1fD', decimal: 18},
//         'USDT':{address: '0xa71EdC38d189767582C38A3145b5873052c3e47a', decimal: 18},
//         'HUSD':{address: '0x0298c2b32eaE4da002a15f36fdf7615BEa3DA047', decimal: 8},
//         'USDL':{address: '0x30e75bd42cE2E415b618A35cE82316F0C21Fd8f0', decimal: 18},
//         'LGD':{address: '0xc46DE6979d0fDC299039C0A23AfBeB1442d9f4F8', decimal: 18},
//         'LGN':{address: '0x9c9Ee782cB90153c4cA663f0e56cfA8dC92eB644', decimal: 18},
//         'LGDLP':{address: '0xCfB65080e58Ac26e4a32d982c948859f34164C2C', decimal: 18},
//         // 'NFTPOOL':{address: '', decimal: 18},
//         }
// const PAIR_ID = {//PAIR ID on UNISWAP => lower case
//         'LGD': '0xcfb65080e58ac26e4a32d982c948859f34164c2c',
//         'ETH': '0x78c90d3f8a64474982417cdb490e840c01e516d4',
//         'LGN': '',
// }
// const EXPLORER = "https://hecoinfo.com/"
// const INFURA = "https://ropsten.infura.io/v3/b290a50e9362493e8882b002b9e44eb3"

export {
  NFT_START,
  STAKING_POOL,
  NETWORK,
  TOKEN_INFO,
  TOTAL_APPROVE,
  CONFIRM_NUMBER,
  EXPLORER,
  INFURA,
  SOCIAL_LINK,
  PAIR_ID,
  MAIN_START,
};
