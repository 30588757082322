import React, { Component } from "react";
import logo from "../assets/nft-legends-logo.svg";
import logosm from "../assets/nft-legends-logo-sm.svg";
import unicrypt from "../assets/images/unicrypt.svg";

import { SOCIAL_LINK, TOKEN_INFO, INFURA, PAIR_ID } from "./constants";
import Web3 from "web3";
// import { myIP } from "./utils";
import TextEffect from "./views/TextEffect";
// import {GODLIKE} from './utils/godlike/godlike'
// import { options } from './utils/godlike/parts/_options';
import Logos from "../assets/CoinLogos";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink,
} from "react-router-dom";
import Home from "./Home";
import Terms from "./views/Terms";

// import PoolInfo from "./PoolInfo";

import $ from "jquery";
import { shortAddress } from "./utils/";

import { AiOutlineSafety } from "react-icons/ai";
import bgvideo from "../assets/Background.mp4";
import detectBrowserLanguage from "detect-browser-language";

import {
  FaMediumM,
  FaTwitter,
  FaTelegramPlane,
  FaGithub,
  FaBullhorn,
} from "react-icons/fa";

export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chainChecker: "uncheck",
    };
  }

  componentDidMount() {
    this.loadWeb3();
    // this.checkChain();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.wallet !== this.props.wallet) {
      this.checkChain();
    }
  }

  async checkChain() {
    const web3 = window.web3;
    this.setState({ chainChecker: "uncheck" });
    let providerID = await web3.eth.net.getId();
    if (providerID !== this.props.wallet.chainId) {
      console.error("Wrong Network");
      this.setState({ chainChecker: "wrong" });
    } else {
      this.setState({ chainChecker: "ok" });
    }
  }

  loadWeb3() {
    if (window.ethereum) {
      //incase browser suport ETH
      window.web3 = new Web3(window.ethereum);
    } else if (window.web3) {
      //incase re-call
      window.web3 = new Web3(window.web3.currentProvider);
    } else {
      //In the rest case
      window.web3 = new Web3(new Web3.providers.HttpProvider(INFURA));
    }
  }

  render() {
    return (
      <Router>
        <header className="nk-header nk-header-opaque">
          <nav className="nk-navbar nk-navbar-top nk-navbar-sticky nk-navbar-transparent nk-navbar-autohide">
            <div className="container">
              <div className="nk-nav-table d-flex">
                <Link to="/" className="mr-auto">
                  <img
                    class="d-none d-sm-inline-block"
                    src={logo}
                    alt=""
                    width="200"
                  />
                  <img src={logosm} class="d-sm-none" alt="" width="60" />
                </Link>

                <ul
                  className="nk-nav nk-nav-right"
                  data-nav-mobile="#nk-nav-mobile"
                >
                  <li>
                    <NavLink
                      to="/"
                      exact
                      activeClassName="active"
                      className="nav-link d-none d-lg-block"
                    >
                      <TextEffect txt="Home" />
                    </NavLink>
                  </li>
                  <li>
                    <a
                      href="https://app.nftlegends.tech"
                      exact
                      // onClick={(e) => {
                      //   e.preventDefault();
                      //   alert("Coming soon...");
                      // }}
                    >
                      <TextEffect txt="Launch app" />
                    </a>
                  </li>
                  <li>
                    <a href="https://docs.nftlegends.tech">
                      <TextEffect txt="Docs" />
                    </a>
                  </li>

                  <li>
                    <a
                      href="https://pancakeswap.finance/"
                      activeClassName="active "
                      className="nav-link"
                      target="_blank"
                    >
                      <TextEffect txt="Buy NFL" />
                    </a>
                  </li>
                  {/* {wallet.status === "connected" ? (
                    <button
                      onClick={() => $("#accountInfoModal").modal("show")}
                      className="nk-btn nk-btn-color-primary nk-btn-bg-white nk-btn-md d-none d-md-inline-block number"
                    >
                      {shortAddress(wallet.account)}
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        wallet.connect();
                      }}
                      // onClick={() => wallet.connect('walletconnect')}
                      className="nk-btn nk-btn-color-white nk-btn-bg-success nk-btn-md d-none d-md-inline-block"
                    >
                      <TextEffect txt={t("header.unlockWallet")} />
                    </button>
                  )} */}
                </ul>
              </div>
            </div>
          </nav>
        </header>

        {/* {this.state.chainChecker === "wrong" && (
          <div className="container">
            <div className="nk-gap-2"></div>
            <div className="text-brand">
              Please connect to Ethereum network to continue
            </div>
          </div>
        )} */}

        <Switch>
          <Route exact path="/">
            <Home wallet={this.props.wallet} />
          </Route>
        </Switch>

        <Terms />
        <div className="nk-gap-4"></div>
        <div className="nk-gap-4"></div>
        <video
          src={bgvideo}
          className="z-1"
          autoPlay="autoplay"
          loop
          playsInline="playsinline"
          muted
        ></video>
        <footer className="footer mt-auto py-3 ">
          <img
            className="nk-footer-top-corner"
            src="https://wp.nkdev.info/godlike/wp-content/themes/godlike/assets/images/footer-corner.png"
            alt=""
          />
          {/* <a target="_blank" rel="noreferrer" href={SOCIAL_LINK.medium}>
            <FaMediumM className="text-secondary text-3 mr-3" />
          </a>
          <a target="_blank" rel="noreferrer" href={SOCIAL_LINK.twitter}>
            <FaTwitter className="text-secondary text-3 mr-3" />
          </a>
          <a target="_blank" rel="noreferrer" href={SOCIAL_LINK.telegram}>
            <FaTelegramPlane className="text-secondary text-3 mr-3" />
          </a>
          <a target="_blank" rel="noreferrer" href={SOCIAL_LINK.github}>
            <FaGithub className="text-secondary text-3" />
          </a> */}
          <a target="_blank" rel="noreferrer" href={SOCIAL_LINK.medium}>
            <FaMediumM className="text-secondary scale-up text-3 mr-5" />
          </a>
          ・
          <a target="_blank" rel="noreferrer" href={SOCIAL_LINK.twitter}>
            <FaTwitter className="text-secondary scale-up text-3 mr-5" />
          </a>
          ・
          <a target="_blank" rel="noreferrer" href={SOCIAL_LINK.telegram}>
            <FaTelegramPlane className="text-secondary  scale-up  text-3 mr-5" />
          </a>
          ・
          <a target="_blank" rel="noreferrer" href={SOCIAL_LINK.telegramAnn}>
            <FaBullhorn className="text-secondary  scale-up  text-3" />
          </a>
          {/* ・
          <a target="_blank" rel="noreferrer" href={SOCIAL_LINK.github}>
            <FaGithub className="text-secondary  scale-up  text-3" />
          </a> */}
          <div className="container py-4 ">
            <p className="text-sm">
              <a
                className="text-white"
                target="_blank"
                rel="noreferrer"
                href="https://app.nftlegends.tech/"
              >
                Pancakeswap
              </a>{" "}
              ・
              <a
                className="text-white"
                target="_blank"
                rel="noreferrer"
                href="https://app.unicrypt.network/dashboard"
              >
                IDO on Unicrypt
              </a>{" "}
              ・
              {/* <a className="text-white" target="_blank"  href="https://drive.google.com/file/d/1AZlcJmF1WJE1km3p0ajUKP6vTqhcvqh3/view">Audit</a> ・  */}
              <a
                className="text-white"
                href=""
                onClick={(e) => {
                  e.preventDefault();
                  $("#termModal").modal("show");
                }}
              >
                Terms of Service
              </a>{" "}
            </p>
            <div className="text-secondary row">
              <div className="col-6 text-right">
                {/* <AiFillSafetyCertificate className="text-success text-2" /> */}
                <img src={unicrypt} width="30" />
                <br />
                <small>
                  <strong className="text-white">Audited by Unicrypt</strong>
                  <br /> You control your funds and private keys.
                </small>
              </div>
              <div className="col-6 text-left">
                <AiOutlineSafety className="text-success text-3" />
                <br />
                <small>
                  <strong className="text-white">
                    Non-Custodial & Secure!
                  </strong>
                  <br /> You control your funds and private keys.
                </small>
              </div>
            </div>
          </div>
        </footer>
      </Router>
    );
  }
}
