const Logos = {
  BUSD: require("./busd.svg").default,
  xUSD: require("./xUSD.png").default,
  USDT: require("./USDT.svg").default,
  USDL: require("./USDL.svg").default,
  HUSD: require("./HUSD.svg").default,
  HT: require("./HT.svg").default,
  NFT: require("./NFT.svg").default,
  NFL: require("./NFL.svg").default,

  DSD: require("./DSD.png").default,
  LGD: require("./LGD.svg").default,
  LGN: require("./LGN.svg").default,
  USDC: require("./usdc.png").default,
  ETH: require("./ETH.png").default,
  DAI: require("./dai.png").default,
  BAC: require("./BAC.png").default,
  DSD: require("./DSD.png").default,
};

export default Logos;
