import React from 'react';

export default class TextEffect extends React.Component {
    constructor(props) {
        super(props);
      }
    render(){
        return <span className="link-effect-4">
        <span className="link-effect-inner">
        <span className="link-effect-l">
          <span>{this.props.txt}</span>
        </span>
        <span className="link-effect-r">
          <span>{this.props.txt}</span>
        </span>
        <span className="link-effect-shade">
          <span>{this.props.txt}</span>
        </span>
      </span>
      </span>
            }
        }