import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  withRouter,
} from "react-router-dom";
import $ from "jquery";
import TextEffect from "./views/TextEffect";
import Countdown from "react-countdown";
import { TweenMax, Quad } from "gsap";

import { BsFillChatQuoteFill } from "react-icons/bs";
import { AiFillFire } from "react-icons/ai";
import Logos from "../assets/CoinLogos";
import vs from "../assets/images/sword.svg";
import market from "../assets/images/playing-cards.svg";
import tour from "../assets/images/tournament.svg";
import nfl from "../assets/images/buy.svg";
import mission from "../assets/images/mission.svg";
import bsc from "../assets/images/binance-smart-chain.png";
import opensea from "../assets/images/OpenSea-Full-Logo.svg";
import polygon from "../assets/images/polygonstudio.png";
import pancakeswap from "../assets/images/pancakeswap.svg";
import unicryptnetwork from "../assets/images/unicrypt_network.svg";
import sweepwidget from "../assets/images/sweepwidget.png";

// white logo
import treasureChest from "../assets/images/treasure-chest.svg";
import story from "../assets/images/story.svg";
import defi from "../assets/images/defi.svg";

//cards
import card from "../assets/nft/card.jpg";
import card1 from "../assets/nft/64.jpg";
import card2 from "../assets/nft/79.jpg";
import card3 from "../assets/nft/99.jpg";
import card4 from "../assets/nft/105.jpg";

//icons
import unicrypt from "../assets/images/unicrypt.svg";
import pancake from "../assets/images/pancake.svg";
import binance from "../assets/images/binance.svg";
import solidproof from "../assets/images/solidproof.png";

import numeral from "numeral";
import { loadUniSwapPrice0, loadAccountBalance } from "./utils";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      APY: 10000,
      getTokenPrice: 0,
    };
  }

  async componentDidMount() {
    $(".project")
      .mouseover(function () {
        $(".project").mousemove(function (e) {
          var x = e.pageX - $(this).offset().left,
            y = e.pageY - $(this).offset().top;

          var px = x / $(this).width(),
            py = y / $(this).height();

          var xx = -15 + 30 * px,
            yy = 15 - 30 * py;

          //TweenMax.killTweensOf($(this));
          TweenMax.to($(this), 0.5, {
            rotationY: xx,
            rotationX: yy,
            rotationZ: 0,
            transformPerspective: 1000,
            ease: Quad.easeOut,
          });
        });
      })
      .mouseout(function () {
        $(this).unbind("mousemove");
        //TweenMax.killTweensOf($(this));
        TweenMax.to($(this), 0.5, {
          rotationY: 0,
          rotationX: 0,
          rotationZ: 0,
          transformPerspective: 1000,
          ease: Quad.easeOut,
        });
      });
    this.loadAPY();
  }

  componentDidUpdate(prevProps) {}

  async loadAPY() {
    //* GET TOKEN PRICE
    let getTokenPrice = await loadUniSwapPrice0("LGD");
    // let getTokenPrice = await loadPriceUnipool('LGD', 'LGD', 'USDL')

    this.setState({ getTokenPrice });
    let stakeTokenPrice = 1;
    let totalLock;
    let APY;

    totalLock = await loadAccountBalance(
      "USDL",
      "0xF1F7EBCbf295C659eC25077F2886703147f55e3F"
    );
    console.log("totalLock", totalLock);
    stakeTokenPrice = 1;

    //* APY CALCULATOR
    APY =
      totalLock == 0
        ? 10000
        : (getTokenPrice * 100 * 43800) / totalLock / stakeTokenPrice;

    this.setState({ APY });
  }

  render() {
    const { t, i18n } = this.props;
    return (
      <main className="main mb-5">
        <div className="nk-page-border">
          <div className="nk-page-border-t"></div>
          <div className="nk-page-border-r"></div>
          {/* <div className="nk-page-border-b"></div> */}
          <div className="nk-page-border-l"></div>
        </div>
        <div className="nk-gap-4"></div>

        <div>
          <div className="nk-header-table">
            <div className="nk-header-table-cell">
              <div className="container">
                <div className="text-center">
                  <h3 className="nk-title-back d-none d-sm-block">
                    NFT LEGENDS
                  </h3>

                  <h1 className="nk-title text-brand">
                    <TextEffect txt="PLAY TO EARN" />
                  </h1>
                </div>
                <div className=" nk-title-sep-icon text-center ">
                  <span className="icon">
                    <AiFillFire />
                  </span>
                </div>
                <p ref="howToPlay" className="nk-title h3">
                  An innovative Play-to-Earn cardfight
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="nk-gap-4"></div>

        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-3 bottom-space">
              <div className="card translate-up h-100 bg-img-1">
                <div className="card-body d-flex flex-column">
                  <div className="nk-ibox-icon">
                    <img src={mission} />
                  </div>
                  <div className="nk-ibox-cont">
                    <h2 className="nk-ibox-title">Complete missions</h2>
                    Earn rewards from a set of selective Daily or Weely
                    Missions, Boss fights and Main Story Campaign. Build your
                    own Legends Deck to fight against the Evil force.
                  </div>
                  <div className="nk-gap-1"></div>
                  <div className="nk-ibox-cont mt-auto">
                    <a
                      href="https://app.nftlegends.tech/"
                      target="_blank"
                      className="nk-btn nk-btn-circle nk-btn-color-main-1"
                    >
                      My missions
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-3 bottom-space">
              <div className="card translate-up h-100 bg-img-1">
                <div className="card-body d-flex flex-column">
                  <div className="nk-ibox-icon">
                    <img src={vs} />
                  </div>
                  <div className="nk-ibox-cont">
                    <h2 className="nk-ibox-title">Duel</h2>
                    Join the PVP duel, defeat your opponent to climb up the
                    Ranks. Top Leaderboards will receive extra Rewards and
                    chance to get rare cards
                  </div>
                  <div className="nk-gap-1"></div>
                  <div className="nk-ibox-cont  mt-auto">
                    <a
                      href="https://app.nftlegends.tech/"
                      target="_blank"
                      className="nk-btn nk-btn-circle nk-btn-color-main-1"
                    >
                      Battle
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 bottom-space">
              <div className="card translate-up h-100 bg-img-1">
                <div className="card-body d-flex flex-column">
                  <div className="nk-ibox-icon">
                    <img src={tour} />
                  </div>
                  <div className="nk-ibox-cont">
                    <h2 className="nk-ibox-title">Tournament</h2>
                    Join the tournament where many masters gather to win the
                    most valuable cards in NFTLegends
                  </div>
                  <div className="nk-gap-1"></div>
                  <div className="nk-ibox-cont  mt-auto">
                    <a
                      href="https://app.nftlegends.tech/"
                      target="_blank"
                      className="nk-btn nk-btn-circle nk-btn-color-main-1"
                    >
                      Join the tour
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 bottom-space">
              <div className="card translate-up h-100 bg-img-1">
                <div className="card-body d-flex flex-column">
                  <div className="nk-ibox-icon ">
                    <img src={market} />
                  </div>
                  <div className="nk-ibox-cont">
                    <h2 className="nk-ibox-title">Cardfight Market</h2>
                    Open Booster Chests to get LIMITED valuable cards, you can
                    sell these items for NFL tokens or keep them to increase
                    strength in the next battles.
                  </div>
                  <div className="nk-gap-1"></div>
                  <div className="nk-ibox-cont  mt-auto">
                    <a
                      href="https://app.nftlegends.tech/"
                      target="_blank"
                      className="nk-btn nk-btn-circle nk-btn-color-main-1"
                    >
                      Go to Cardfight Market
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="nk-gap-6"></div>

        <div class="nk-box bg-dark-1">
          <div class="container text-center">
            <div class="nk-gap-6"></div>
            <div class="nk-gap-2"></div>
            <h3 className="nk-title-back d-none d-sm-block">NFT LEGENDS</h3>
            <h1 className="nk-title text-brand">
              <TextEffect txt="About The Game" />
            </h1>
            <div class="nk-gap-3"></div>
            <p class="lead">
              NFTLegends is a Play-to-Earn cardfight game where users can
              collect and customize their own Legends Deck (army) to compete in
              PVE or PVP settings for Rewards. The cards feature different types
              of monster (dragons, knights, machines) from many Races such as
              Dark Empire, Dragon Nation, Genesis, Paladin and many others. It
              is a game where everyone competes to become the best using their
              skills.
            </p>
            <div class="nk-gap-2"></div>
            <div class="row no-gutters">
              <div class="col-md-4">
                <div class="nk-box-2 nk-box-line">
                  <div class="nk-counter-3">
                    <div class="nk-count">8+</div>
                    <h3 class="nk-counter-title h4">Unique Classes</h3>
                    <div class="nk-gap-1"></div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="nk-box-2 nk-box-line">
                  <div class="nk-counter-3">
                    <div class="nk-count">106+</div>
                    <h3 class="nk-counter-title h4">Hero Types</h3>
                    <div class="nk-gap-1"></div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="nk-box-2 nk-box-line nk-box-last">
                  <div class="nk-counter-3">
                    <div class="nk-count">50+</div>
                    <h3 class="nk-counter-title h4">Epic Bosses</h3>
                    <div class="nk-gap-1"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="nk-gap-2"></div>
            <div class="nk-title h3">
              And endless possible deck combinations…
            </div>
            <div class="nk-gap-2"></div>

            <div class="row">
              <div class="col-md-8 offset-md-2">
                <div
                  class="nk-plain-video"
                  data-video-thumb="assets/images/video-6-thumb.jpg"
                >
                  <iframe
                    src="https://player.vimeo.com/video/581570964?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                    frameborder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowfullscreen=""
                    title="SH-gameplot-final.mp4"
                    // style="position:absolute;top:0;left:0;width:100%;height:100%"
                  ></iframe>
                </div>
              </div>
            </div>
            <div class="nk-gap-2"></div>
            <a
              href="https://app.nftlegends.tech"
              class="nk-btn nk-btn-x3 nk-btn-circle nk-btn-3d nk-btn-color-main-1"
            >
              Explore the game
            </a>
            <div class="nk-gap-2"></div>
            <div class="nk-gap-6"></div>
          </div>
        </div>
        <div class="nk-gap-6"></div>

        <div>
          <div className="nk-header-table">
            <div className="nk-header-table-cell">
              <div className="container">
                <div className="text-center">
                  <h3 className="nk-title-back d-none d-sm-block">
                    NFT LEGENDS
                  </h3>
                  <h1 className="nk-title text-brand">
                    <TextEffect txt="Game System" />
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="nk-gap-4"></div>

        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-md-6 col-lg-4 bottom-space">
              <div className="card translate-up h-100 bg-img-1  ">
                <div className="card-body d-flex flex-column">
                  <div className="nk-ibox-icon">
                    <img src={treasureChest} />
                  </div>
                  <div className="nk-ibox-cont">
                    <h2 className="nk-ibox-title text-brand">Booster Chests</h2>
                    Finding your first companions by opening chests. Each chest
                    has a different chance of getting high valuable cards
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-4 bottom-space">
              <div className="card translate-up h-100 bg-img-2  ">
                <div className="card-body d-flex flex-column">
                  <div className="nk-ibox-icon">
                    <img src={story} />
                  </div>
                  <div className="nk-ibox-cont">
                    <h2 className="nk-ibox-title text-brand">Story Mode</h2>
                    Clear the Story mode and defeat a variety of rivals in the
                    game to earn NFL Tokens.
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 bottom-space">
              <div className="card translate-up h-100 bg-img-3">
                <div className="card-body d-flex flex-column">
                  <div className="nk-ibox-icon">
                    <img src={market} />
                  </div>
                  <div className="nk-ibox-cont">
                    <h2 className="nk-ibox-title text-brand">My Deck</h2>
                    Create a deck that’s uniquely you, and customize it with
                    your favorite set of cards. Enjoy fully detailed card
                    interface and come up with the best strategy that suits your
                    playstyle.
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 bottom-space">
              <div className="card translate-up h-100 bg-img-4">
                <div className="card-body d-flex flex-column">
                  <div className="nk-ibox-icon ">
                    <img src={vs} />
                  </div>
                  <div className="nk-ibox-cont">
                    <h2 className="nk-ibox-title text-brand">Fight mode</h2>
                    With the rules optimized for mobile gaming, you can enjoy
                    the NFTLegends cardfights. Battle with CPU opponents, or
                    take on players from all over the world in real-time online
                    battles!
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 bottom-space">
              <div className="card translate-up h-100 bg-img-5">
                <div className="card-body d-flex flex-column">
                  <div className="nk-ibox-icon ">
                    <img src={defi} />
                  </div>
                  <div className="nk-ibox-cont">
                    <h2 className="nk-ibox-title text-brand">
                      Defi integration
                    </h2>
                    Add & stake NFL/BNB LP pairs to earn $LUCKY for better
                    chance of getting high valuable cards!
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="nk-gap-6"></div>

        <div>
          <div className="nk-header-table">
            <div className="nk-header-table-cell">
              <div className="container">
                <div className="text-center">
                  <h3 className="nk-title-back d-none d-sm-block">
                    NFT LEGENDS
                  </h3>
                  <h1 className="nk-title text-brand">
                    <TextEffect txt="Card Rarity" />
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="nk-gap-4"></div>
        <div className="container">
          <div className="row">
            <div className="col-sm-6 col-md-3">
              <div className="project mx-auto">
                <img className="img-nft" src={card1} />
              </div>

              <div className="nk-gap-3"></div>
            </div>
            <div className="col-sm-6 col-md-3">
              <div className="project mx-auto">
                <img className="img-nft" src={card2} />
              </div>

              <div className="nk-gap-3"></div>
            </div>
            <div className="col-sm-6 col-md-3">
              <div className="project mx-auto">
                <img className="img-nft" src={card3} />
              </div>

              <div className="nk-gap-2"></div>
            </div>
            <div className="col-sm-6 col-md-3">
              <div className="project mx-auto">
                <img className="img-nft" src={card4} />
              </div>

              <div className="nk-gap-3"></div>
            </div>
          </div>
        </div>
        <div class="nk-gap-6"></div>

        <div>
          <div className="nk-header-table">
            <div className="nk-header-table-cell">
              <div className="container">
                <div className="text-center">
                  <h3 className="nk-title-back d-none d-sm-block">
                    NFT LEGENDS
                  </h3>
                  <h1 className="nk-title text-brand">
                    <TextEffect txt="ILO" />
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="nk-gap-4"></div>
        <div class="container">
          <div class="nk-gap-2"></div>
          <div class="row vertical-gap lg-gap">
            <div class="col-md-4">
              <div class="nk-ibox d-flex flex-column h-100">
                <div class="nk-ibox-icon nk-ibox-icon-circle">
                  <img src={unicrypt} />
                </div>
                <div class="nk-ibox-cont">
                  <h2 class="nk-ibox-title">ILO on Unicrypt</h2> Participate in
                  NFTLegends ILO at 13:00 UTC August 1, 2021 to obtain NFL
                  Tokens on the first day.
                </div>
                <div class="nk-gap-2"></div>
                <div class="nk-ibox-cont mt-auto">
                  <a
                    class="nk-btn nk-btn-circle nk-btn-color-main-1"
                    href="https://app.unicrypt.network/dashboard"
                    target="_blank"
                  >
                    Join ILO
                  </a>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="nk-ibox d-flex flex-column h-100">
                <div class="nk-ibox-icon nk-ibox-icon-circle">
                  <img src={pancake} />
                </div>
                <div class="nk-ibox-cont">
                  <h2 class="nk-ibox-title">Buy NFL on Pancake</h2> Trade
                  NFL/BNB pairs on PancakeSwap right after the ILO.
                </div>
                <div class="nk-gap-2"></div>

                <div class="nk-ibox-cont mt-auto">
                  <a
                    class="nk-btn nk-btn-circle nk-btn-color-main-1"
                    href="https://pancakeswap.finance/"
                    target="_blank"
                  >
                    After ILO
                  </a>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="nk-ibox d-flex flex-column h-100">
                <div class="nk-ibox-icon nk-ibox-icon-circle">
                  <img src={binance} />
                </div>
                <div class="nk-ibox-cont">
                  <h2 class="nk-ibox-title">Others exchange</h2> More CEX/DEX
                  will be added after the ILO.
                </div>
                <div class="nk-gap-2"></div>

                <div class="nk-ibox-cont mt-auto">
                  <a
                    class="nk-btn nk-btn-circle nk-btn-color-main-1 disabled"
                    href="https://pancakeswap.finance/"
                    target="_blank"
                  >
                    Coming soon
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="nk-gap-2"></div>
          <div class="nk-gap-6"></div>
        </div>
        <div class="container">
          <div class="nk-gap-2"></div>
          <h2 class="nk-title h1">
            <TextEffect txt="NFT Legends Partners" />
          </h2>
          {/* <h1 className="nk-title text-brand">
            <TextEffect txt="About The Game" />
          </h1> */}
          <div class="nk-footer-logos">
            <a href="https://polygonstudios.com/" target="_blank">
              <img class="nk-img scale-up" src={polygon} alt="" width="150" />
            </a>
            <a href="https://pancakeswap.finance/" target="_blank">
              <img
                class="nk-img scale-up"
                src={pancakeswap}
                alt=""
                width="160"
              />
            </a>
            <a
              href="https://app.unicrypt.network/amm/pancake-v2/tokens"
              target="_blank"
            >
              <img
                class="nk-img scale-up"
                src={unicryptnetwork}
                alt=""
                width="160"
              />
            </a>
            <a href="https://www.binance.org/en/smartChain" target="_blank">
              <img class="nk-img scale-up" src={bsc} alt="" width="130" />
            </a>
            <a href="https://opensea.io/" target="_blank">
              <img class="nk-img scale-up" src={opensea} alt="" width="150" />
            </a>
            <a href="https://solidproof.io/" target="_blank">
              <img
                class="nk-img scale-up"
                src={solidproof}
                alt=""
                width="160"
              />
            </a>
            <a href="https://sweepwidget.com/" target="_blank">
              <img
                class="nk-img scale-up"
                src={sweepwidget}
                alt=""
                width="160"
              />
            </a>
          </div>
          {/* <div class="nk-gap"></div> */}
          <div className=" nk-title-sep-icon text-center ">
            <span className="icon">
              <AiFillFire />
            </span>
          </div>
          <p>
            NFTLegends ecosystem is the perfect combination of NFT gaming and
            DeFi that enables users to have fun and earn profit simultaneously.
            More than a game, NFTLegends also has a strong community helping
            players to build the best Deck to earn money from game.
          </p>

          <div class="nk-gap-4"></div>
        </div>
      </main>
    );
  }
}

export default withRouter(Home);
