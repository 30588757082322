import React from "react";

export default class Terms extends React.Component {
  render() {
    return (
      <div
        className="modal fade"
        id="termModal"
        tabIndex="-1"
        aria-labelledby="exampleModalScrollableTitle"
        aria-modal="true"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalScrollableTitle">
                Terms of Services
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body text-left text-sm">
              <p>
                Certain available services through our website (having its URL
                at https://nftlegends.tech , the “Site”) (the “Services”), you
                are agreeing that you will be bound by the following terms and
                conditions (“Terms of Use”) when you access the Site and use the
                Services. Please review the Agreement carefully and do not
                register the Account, access the Site, or use the Services if
                you decide not to agree with the Agreement.
              </p>
              <p>
                Please note that the Agreement may be changed, amended, or
                updated by NFTLegends at any time. Additional terms will be
                available with the Services, and those additional terms will
                become part of your Agreement with us if you decide to use the
                Services.
              </p>
              <p>
                <strong>1. ACCEPTANCE OF AGREEMENT</strong>
              </p>
              <p>
                By accessing or using the Services and this Site by any means
                (including but not limited to, via devices or services provided
                by any other third parties), you acknowledge and agree to be
                bound by the terms and conditions of the Agreement, which may be
                modified in accordance with Article 8.2 of these Terms of Use.
              </p>
              <p>
                <strong>2. ELIGIBILITY</strong>
              </p>
              <p>
                Under the laws applicable to you to access and use the Services
                and this Site, it is assumed that you agree to the Agreement and
                represent and warrant that you:
              </p>
              <p>
                (1) are at least 20 years of age, or at least the minimum age
                required by applicable law such that the Services may be
                lawfully provided to you without parental consent;
              </p>
              <p>
                (2) agree to the Agreement (and are legally capable of doing
                so);
              </p>
              <p>
                (3) do not reside in and are not a citizen of a country with
                which United States persons are prohibited from doing business
                (e.g. countries subject to sanctions as identified by The Office
                of Foreign Assets Control of the U.S. Department of the
                Treasury), and you are not a person or legal entity identified
                in the Specially Designated Nationals And Blocked Persons List
                (“SDN List”);
              </p>
              <p>
                (4) do not reside in and are not a citizen of the United States,
                People’s Republic of China or other countries where accessing
                and/or using the Services is prohibited; are not prohibited by
                relevant applicable laws and regulations from accessing and/or
                using the Services;
              </p>
              <p>
                (5) have not previously been suspended or removed from the Site;
              </p>
              <p>
                (6) have all necessary authorizations and consents to carry out
                all transactions initiated from your Account;
              </p>
              <p>
                (7) have reviewed the risk disclosure statement contained in the
                Agreement, and consent to the bearing of all risks described
                therein.
                <br />
                If you are not qualified as above, please do not access or use
                the Services and this Site, otherwise you and your parents or
                legal guardian shall be responsible for all legal and financial
                responsibility and liabilities resulting therefrom, and
                NFTLegends shall have the right to claim against you and your
                parents or legal guardian for damages caused to NFTLegends as a
                result of such access or use.
              </p>
              <p>
                <strong>
                  3. ANTI-FRAUD, ANTI-MONEY LAUNDERING AND COUNTER-TERRORIST{" "}
                </strong>
              </p>
              <p>
                NFTLegends and its Affiliates implement certain Anti-fraud,
                Anti-Money Laundering and Counter-Terrorist policies and
                procedures (“Anti-Fraud, Anti-Money Laundering and
                Counter-Terrorist Policies”). Therefore, NFTLegends and its
                Affiliates insist on a comprehensive and thorough customer due
                diligence process, implementation, ongoing analysis, and
                reporting. This includes monitoring of suspicious transactions
                and compulsory or voluntary reporting to regulators. It is
                necessary for NFTLegends to keep certain information and
                documentation in file in accordance to applicable laws and
                policies and to fulfill contractual obligations to third
                parties; NFTLegends hereby expressly reserves the right to keep
                such information and documentation and to disclose such
                information and documents to government authorities. Also, for
                the purpose of the Anti-Fraud, Anti-Money Laundering and
                Counter-Terrorist Policies, NFTLegends reserves the right to
                determine, in its sole discretion, the appropriate verification
                level and criteria and the right to downgrade Users without
                prior notice, and may, from time to time, implement policies
                restricting verification levels and criteria by nationality,
                country of residence, or any other factor, whether or not due to
                change in laws and regulations.
              </p>
              <p>
                <strong>4. RISK STATEMENT </strong>
              </p>
              <p>
                (1) You shall be aware that trading or owning tokens can be
                highly risky. The risks include, but not limited to: (a) that
                tokens may fluctuate considerably in value or have no value; (b)
                that the market liquidity of tokens may be affected by various
                reasons and sometimes it is difficult or impossible to liquidate
                the tokens; and (c) that the blockchain network may face
                unexpected issues and is forced to go offline due to
                regulations, issuer viabilities or market conditions. The above
                mentioned are just the most common risks you may encounter when
                trading or owning tokens and they are not the exhaustive list of
                all risks.
              </p>
              <p>
                (2) The Site recommends you not to use the Services without
                adequate technical knowledge or consulting the relevant
                specialist first. You hereby represent and warrant that you have
                made enough independent investigation in tokens being possessed
                or traded by you. By accessing or using the Services, you are
                deemed to be fully aware of and accept all relevent risks.
              </p>
              <p>
                (3) The Site and any of its employees or agents will not render
                any advice to you concerning the valuation, market and trading
                of tokens or the tax consequence and other legal application
                relating to trading or possessing tokens. If there is any, it
                shall only be incidental to the Services and thus no obligation
                and/or liability will be imposed on the Site. You are solely
                responsible with respect to evaluating any transactions you
                conduct through the Services.
              </p>
              <p>
                (4) The Site may undergo unforeseeable financial, technical or
                operational difficulties, errors or failures, including but not
                limited to cyber-attacks, malicious nodes or Sybil attacks,
                which may cause destruction, disruption, malfunction,
                interruption, alteration, termination, temporary shutdown or
                delay of the Services. You understand the Site’s difficulty in
                dealing with such problem and agree not to claim against the
                Site for any loss or damage incurred by such situation.
              </p>
              <p>
                (5) The Site may in its discretion suspend or discontinue any or
                all components of the Services in relation to a certain type of
                token at any time in the event of a fork or a cyber-attack of
                such token or due to any other reason the Site considers
                reasonable.
              </p>
              <p>
                (6) The blockchain technology and its operating rules may change
                at any time and the blockchain networks may become unavailable
                or be subject to interruption, attack or failure due to various
                reasons. You understand that the underlying software through
                which the token is generated and distributed and the blockchain
                network is established is not owned, operated or controlled by
                the Site. The Site does not make any promise or guaranty on the
                consistency, availability, non-interruption, security and
                functionality of such blockchain technology, operating rules,
                networks and software and you agree to bear any and all risks
                arising therefrom or in relation thereto.
              </p>
              <p>
                <strong>5. MEMBER CONDUCT</strong>
              </p>
              <p>You agree not to use the Services and this Site to:</p>
              <p>
                (1) upload, post, transmit, distribute or otherwise make
                available any Content that is illegal, offensive, threatening,
                abusive, harassing, pornographic, tortious, defamatory, vulgar,
                obscene, libelous, invasive of another’s privacy, hateful, or
                racially, ethnically, or otherwise objectionable;
              </p>
              <p>
                (2) promote discrimination, racism, hatred, harassment or harm
                against any person or group;
              </p>
              <p>
                (3) impersonate any other third party or falsely state or
                otherwise misrepresent your affiliation with any other third
                party;
              </p>
              <p>
                (4) upload, post, transmit, distribute or otherwise make
                available any Content that you do not have a right to make
                available under any law or under contractual or fiduciary
                relationships (such as inside information, proprietary and
                confidential information learned or disclosed as part of
                employment relationships or under nondisclosure agreements);
              </p>
              <p>
                (5) upload, post, transmit, distribute or otherwise make
                available any Content that violates any patent, trademark, trade
                secret, copyright, or other proprietary rights of any other
                third party;
              </p>
              <p>
                (6) upload, post, transmit, distribute or otherwise make
                available any unsolicited or unauthorized advertising,
                promotional materials, spam, pyramid schemes, or any other form
                of solicitation;
              </p>
              <p>
                (7) upload, post, transmit, disseminate or otherwise make
                available any material containing software viruses, malicious
                software, programs, worms or any other computer code, files or
                programs designed to interrupt, destroy or limit the
                functionality of any computer software or hardware or
                telecommunications equipment;
              </p>
              <p>
                (8) disobey, violate or disregard, to the extent applicable to
                you, any code of conducts, requirements, procedures, policies,
                or regulations of the Services and this Site;
              </p>
              <p>
                (9) intentionally or unintentionally violate or encourage any
                conduct that would violate any applicable local, state, national
                or international law;
              </p>
              <p>
                (10) promote, encourage or engage in terrorism violence or
                financial contribution to terrorism;
              </p>
              <p>
                (11) stalk or otherwise harass other users of the Services and
                this Site;
              </p>
              <p>
                (12) collect or store personal data about other users of the
                Services and this Site without their prior express consent or
                permission; and
              </p>
              <p>
                (13) encourage, enable, or assist any other third party to do
                any of the foregoing.
              </p>
              <p>
                <strong>6. LINKS TO OTHER WEBSITES</strong>
              </p>
              <p>
                This Site may contain links to websites controlled or offered by
                third parties (other than NFTLegends and its Affiliate). Such
                links are provided only as a convenience and NFTLegends does not
                imply any endorsement or recommend any products or services
                offered by or information contained in any of these third-party
                sites. NFTLegends hereby disclaims all liability for all
                information, materials, products or services posted, offered or
                accessible at any of the third-party sites linked to this Site.
                NFTLegends shall not be responsible for the content on or
                privacy practices of third party sites linked to from the
                websites. You acknowledge that you should bear sole
                responsibility for and assume all risks arising from your use of
                any such websites or resources. By using the Services and this
                Site, you expressly release NFTLegends from any and all
                liability arising from your use of any third-party website or
                online resources. Accordingly, you shall be aware when you leave
                the Services and this Site and to review the relevant terms of
                each website that you may visit.
              </p>
              <p>
                <strong>7. DISCLAIMER AND LIABILITY</strong>
              </p>
              <p>
                (1) The Site only provides the Services on an “as is” and
                “commercially available” basis and makes no warranties of any
                kind, either express or implied, including, but not limited to,
                that the Services will always be accurate, reliable, error-free,
                secure, safe, complete and/or available, that the Services will
                always function without disruption, delay and/or imperfection,
                and the implied warranties of merchantability, fitness for any
                particular purpose, course of performance, course of dealing,
                titles and non-infringement relating to the Services. The Site
                cannot prevent, avoid or mitigate attacks on or technical errors
                or failures of the Services, internet transmission or blockchain
                networks.
              </p>
              <p>
                (2) The Site shall not be liable for any action, behavior and
                information of third parties.
              </p>
              <p>
                (3) The Site only provides a platform for transaction of tokens,
                thus makes no representation that all tokens available in the
                Services are safe, legal and/or of liquidity.
              </p>
              <p>
                (4) This Article shall apply to the maximum extent permitted by
                applicable laws.
              </p>
              <p>
                <strong>8. GENERAL PROVISIONS</strong>
              </p>
              <p>(1) No Class Action</p>
              <p>
                To the extent permitted by law, you and NFTLegends may bring
                claims against the other only in your or its individual
                capacity, and not as a plaintiff or class member in any alleged
                class, representative, consolidated or multi-claimant
                proceeding. You and NFTLegends each waive any right to a jury
                trial.
              </p>
              <p>(2) Modifications</p>
              <p>
                NFTLegends reserves the right, at its sole discretion, to
                modify, discontinue or terminate the Services and this Site or
                to amend this Agreement, at any time and without prior notice.
                If a revision significantly reduces your rights, we will notify
                you (by, for instance, posting on this page). By continuing to
                use or access the Services after the revisions come into
                effect,you agree to be bound by the revised Agreement.
              </p>
              <p>(3) No Advice or Brokerage</p>
              <p>
                NFTLegends does not provide any investment, financial, tax, or
                legal advice, or advice on trading techniques, models,
                algorithms, or any other schemes, nor does NFTLegends broker
                trades on your behalf. You are solely responsible for
                determining whether any investment, investment strategy or
                related transaction is appropriate for you on the basis of your
                personal investment objectives, financial circumstances and risk
                tolerance.
              </p>
              <p>(4) Entire Agreement</p>
              <p>
                The Agreement constitutes the entire understanding between you
                and NFTLegends and governs your use of Services and this Site
                and any prior or contemporary representations, whether oral or
                written are hereby superseded.
              </p>
              <p>(5) Assignment</p>
              <p>
                Unless otherwise stated herein, you may not assign any of your
                rights under the Agreement, and any such attempt will be void.
                NFTLegends may assign or transfer its rights to any of its
                affiliates or subsidiaries, or to any successor in the interest
                of any business associated with the Services at its sole
                discretion, without restriction. Subject to the foregoing, the
                Agreement will bind and inure to the benefit of the parties,
                their successors and permitted assigns.
              </p>
              <p>
                (6) Waiver and Severability
                <br />
                NFTLegends failure to enforce a provision is not a waiver of its
                right to do so later. In case any one or more of the provisions
                contained in the Agreement is for any reason held to be invalid
                or unenforceable in any respect, the remaining provisions of the
                Agreement and the remaining portion of any provision held
                invalid or unenforceable in part shall continue in full force
                and effect.
              </p>
              <p>(7) Change of Control</p>
              <p>
                In the event that NFTLegends is acquired by or merged with a
                third party entity, we reserve the right, in any of these
                circumstances, to transfer or assign the information we have
                collected from you as part of such merger, acquisition, sale, or
                other change of control.
              </p>
              <p>(8) Survival</p>
              <p>
                All representations, warranties, covenants and agreements of the
                Agreement which by their nature extend beyond the expiry or
                termination of the Agreement, including, without limitation,
                articles relating to suspension or termination shall survive the
                termination or expiry of the Agreement.
              </p>
              <p>(9) Force Majeure</p>
              <p>
                NFTLegends shall have no obligation to perform the Services if
                its failure to do so is caused by or results from any act of
                God, governmental action, terrorists, civil disturbance, war,
                strike or other labor dispute, natural disaster or Internet
                services or network service providers, failure of equipment
                and/or software, rapid price fluctuation of any given supported
                Digital Assets, or any other cause or circumstance, whether
                similar or dissimilar to the aforementioned causes or
                circumstances, beyond the reasonable control of NFTLegends.
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="nk-btn nk-btn-xs nk-btn-color-white nk-btn-bg-success"
              >
                Accept
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
