import "./App.css";
import React, { Suspense } from "react";
import { NETWORK } from "./components/constants";
import Header from "./components/Header";
import "bootstrap/dist/css/bootstrap.css";
// import 'bootstrap/dist/js/popper.min.js';
import "bootstrap";
import "./assets/style/font.css";
import "./assets/style/godlike.css";
import "./assets/style/custom.css";
// import './assets/style/ionicons/css/ionicons.min.css';

import { useWallet, UseWalletProvider } from "use-wallet";

function App() {
  const wallet = useWallet();
  return (
    <Suspense fallback="loading">
      <div className="App d-flex flex-column">
        <Header wallet={wallet} />
        {/* <Coming/> */}
      </div>
    </Suspense>
  );
}

export default () => (
  <UseWalletProvider chainId={NETWORK}>
    <App />
  </UseWalletProvider>
);
