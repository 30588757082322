import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import './i18next'
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";

import en_com from "./locales/en/translation.json";
import cn_com from "./locales/cn/translation.json";

i18next.init({
  interpolation: { escapeValue: false },  // React already does escaping
  lng: 'en',
  resources: {
    'en': {
      'common': en_com
    },
    'cn': {
        'common': cn_com
    },
  },
  react: { 
    useSuspense: false 
  }
});

ReactDOM.render(
  <React.StrictMode>
      <I18nextProvider i18n={i18next}>
            <App/>
        </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
